<template>
    <div class="cus-cascader">
        <div class="cus-cascader-left">
            <div class="cascader-header">
                <el-checkbox v-model="allChecked" @change="handleAllChange">全{{ cascaderTitle }}</el-checkbox>
            </div>
            <div class="cascader-body">
                <div v-for="item in cascaderData" :key="'parent' + item.value" class="parent-item" :class="{'checked': item.value === cascaderCurrent}" @click="handleParentClick(item)">
                    <div class="cascader-menu" :class="{'checked': item.value === cascaderCurrent}">
                        <div class="cascader-menu-text">{{ item.label }}</div>
                        <div class="cascader-menu-icon">
                            <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>

                    <div class="cascader-children">
                        <div class="cascader-header">
                            <el-checkbox v-model="item.checked" @change="(value) => handleParentChange(value, item)">{{ item.label }}全部</el-checkbox>
                        </div>
                        <div class="cascader-body">
                            <label v-for="citem in item.children" :key="'chidren' + citem.value" class="cascader-item">
                                <el-checkbox v-model="citem.checked" @change="(value) => handleChildrenChange(value, item, citem)">{{ citem.label }}</el-checkbox>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="cus-cascader-right">&nbsp;</div>
    </div>
</template>

<script>
export default {    
    name: 'ComponentsCusCascader',
    props: {
        cascaderTitle: {
            type: String,
            default: '',
        },
        cascaderData: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    model: {
        prop: 'cascaderData', // 指定自己的pfoo属性接受数值
        event: 'valChange', // 指定自己的event1事件回送数据给父组件
    },
    data() {
        return {
            allChecked: false,
            cascaderValue: this.cascaderData,
            cascaderCurrent: '',
        };
    },
    watch: {
        cascaderData: {
            handler(val) {
                this.cascaderValue = val;
                this.cascaderCurrent = val[0]?.value || '';

                this.handleInit();
            },
        },
    },
    methods: {
        // 初始化
        handleInit() {            
            this.allChecked = false;
        },

        // 点击父级
        handleParentClick(item) {
            this.cascaderCurrent = item.value;
        },

        // 全选
        handleAllChange(value) {
            if (value) {
                this.cascaderValue.forEach((item) => {
                    item.checked = false;

                    if (item.children && item.children.length) {
                        item.children.forEach((citem) => {
                            citem.checked = false;
                        });
                    }
                });

                this.handleValueChange();
            }
        },

        // 选父级
        handleParentChange(value, item) {
            if (value) {
                item.children.forEach((citem) => {
                    citem.checked = false;
                });
            }

            this.allChecked = false;

            this.handleValueChange();
        },

        // 选子级
        handleChildrenChange(value, item, citem) {
            // console.log(item);
            item.checked = false;

            this.allChecked = false;

            this.handleValueChange();
        },

        // 改变值
        handleValueChange() {
            const { cascaderValue } = this;

            this.$emit('valChange', cascaderValue);

            this.$emit('change', {
                type: 'value',
                data: cascaderValue,
            });
        },
    },
}
</script>

<style lang="scss">
.cus-cascader {
    .el-checkbox__label {
        font-size: 12px;
    }
}
</style>

<style lang="scss" scoped>
.cus-cascader {
    position: relative;
    height: 180px;

    font-size: 12px;
    line-height: 30px;
}

.cus-cascader-left {
    width: 40%;
    height: 100%;
}

.cascader-header {
    padding: 0 10px;
    height: 30px;
    border-bottom: 1px solid #ccc;

    overflow: hidden;
}

.cascader-body {
    height: 150px;

    overflow-y: auto;
}

.cascader-menu {
    display: flex;
    padding-left: 10px;
    height: 30px;

    cursor: pointer;

    &:hover,
    &.checked {
        background-color: #FFEEEE;
    }

    &.checked {
        color: #E03A3E;

        .cascader-menu-icon {
            display: block;
        }
    }

    &-text {
        flex: 1;

        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &-icon {
        display: none;
        width: 30px;

        text-align: center;
    }
}

.cascader-item {
    display: block;
    padding: 0 10px;

    cursor: pointer;

    &:hover {
        background-color: #FFEEEE;
    }
}

.cascader-children {
    display: none;
    position: absolute;
    top: 0;
    left: 40%;
    bottom: 0;

    width: 60%;
    border-left: 1px solid #ccc;
}

.parent-item {
    &.checked {
        .cascader-children {
            display: block;
            z-index: 5;
        }
    }
}
</style>